import React from 'react';
import config from '../../config';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faWordpress,
  faTelegram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLink,
  faEnvelope, 
  faHeart,
  faPhone,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons";
import Zoom from "react-reveal/Zoom";

import "../assets/sass/_social.scss";

const shareButtonsIcons = [
  faLink,
  faHeart,
  faInstagram,
  faEnvelope,
  faPhone,
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  faWordpress,
  faPodcast,
  faTelegram,
  faWhatsapp
];

library.add(...shareButtonsIcons);
export default function SocialLinks({ sectionP=true, links = config.socialLinks}) {
  let socialLinks = links.map((social,idx) => {
      let { icon, url, title="", name="" } = social;
      if(!url.endsWith("/") && name.toLowerCase() !== "email" ) {
        url += "/";
      }
      return (
        <Zoom key={url}>
          <a
            href={url}
            className={`mx-2`}
            rel="noopener noreferrer"
            aria-label={title}
            target="_blank"
            title={title}
          >
            <FontAwesomeIcon icon={icon} />
          </a>
        </Zoom>
      );
    });
  
  return sectionP ? (
    <section className="social-section bg-fg">
      <div className="social d-flex justify-content-center">{socialLinks}</div>
    </section>
    ) : (<span className="social">{socialLinks}</span>)
  ;
}
